:root {
  --light: #fff;
  --dark: #000;
  --gray: gray;
  --light-gray: #c6c6c6;
  --red: red;
  --dark-red: maroon;
  --green: green;
  --blue: blue;
  --light-blue: teal;
  --dark-blue: navy;
  --outset: 3px outset var(--light);
  --inset: inset var(--light);
}

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
}

html {
  height: 100vh;
}

body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-blue);
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100%;
  overflow: hidden;
  background: var(--light-gray);
  border: var(--outset);
}